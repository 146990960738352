/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Galerie svatba"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center pb--30 pt--60" name={"uvod"} style={{"backgroundColor":"rgba(233,230,223,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":860}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--62 pb--25 pt--25" content={"<span style=\"color: rgb(2, 0, 0);\">Svatba</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--60" name={"mfskornurkj"} style={{"paddingBottom":0}} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/32646/02a338fb3d814c3aa757935cca50b0e4_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32646/02a338fb3d814c3aa757935cca50b0e4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32646/02a338fb3d814c3aa757935cca50b0e4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32646/02a338fb3d814c3aa757935cca50b0e4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32646/02a338fb3d814c3aa757935cca50b0e4_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32646/02a338fb3d814c3aa757935cca50b0e4_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Hudlická stodola - svatba"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/32646/a98a76ddf1be4b08afb05c110bc25254_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32646/a98a76ddf1be4b08afb05c110bc25254_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32646/a98a76ddf1be4b08afb05c110bc25254_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32646/a98a76ddf1be4b08afb05c110bc25254_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32646/a98a76ddf1be4b08afb05c110bc25254_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Hudlická stodola - svatba"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/32646/57232936c64d4df19120b3e94259850b_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32646/57232936c64d4df19120b3e94259850b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32646/57232936c64d4df19120b3e94259850b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32646/57232936c64d4df19120b3e94259850b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32646/57232936c64d4df19120b3e94259850b_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Hudlická stodola - svatba"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/32646/1f555a2d05b5414087f7c80382561809_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32646/1f555a2d05b5414087f7c80382561809_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32646/1f555a2d05b5414087f7c80382561809_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32646/1f555a2d05b5414087f7c80382561809_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32646/1f555a2d05b5414087f7c80382561809_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32646/1f555a2d05b5414087f7c80382561809_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Hudlická stodola - svatba"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"8ou8y9r36f6"} style={{"paddingTop":0}} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/32646/8a4dca152f6547a7b455c0f9be206a22_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32646/8a4dca152f6547a7b455c0f9be206a22_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32646/8a4dca152f6547a7b455c0f9be206a22_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32646/8a4dca152f6547a7b455c0f9be206a22_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32646/8a4dca152f6547a7b455c0f9be206a22_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32646/8a4dca152f6547a7b455c0f9be206a22_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Hudlická stodola - svatba"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/32646/c2652a99861c4f59bcc75a0d0f6f61cb_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32646/c2652a99861c4f59bcc75a0d0f6f61cb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32646/c2652a99861c4f59bcc75a0d0f6f61cb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32646/c2652a99861c4f59bcc75a0d0f6f61cb_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32646/c2652a99861c4f59bcc75a0d0f6f61cb_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Hudlická stodola - svatba"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/32646/7e26a489c0114e6e9c054a382e3f258c_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32646/7e26a489c0114e6e9c054a382e3f258c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32646/7e26a489c0114e6e9c054a382e3f258c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32646/7e26a489c0114e6e9c054a382e3f258c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32646/7e26a489c0114e6e9c054a382e3f258c_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Hudlická stodola - svatba"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/32646/415b3749dc7249789ccd45d3baa4d27f_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32646/415b3749dc7249789ccd45d3baa4d27f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32646/415b3749dc7249789ccd45d3baa4d27f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32646/415b3749dc7249789ccd45d3baa4d27f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32646/415b3749dc7249789ccd45d3baa4d27f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/32646/415b3749dc7249789ccd45d3baa4d27f_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Hudlická stodola - svatba"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Adresa<br>"}>
              </Title>

              <Text className="text-box" content={"Hudlice, okres Beroun"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--43 swpf--uppercase ls--0 lh--1" content={"Hudlická stodola<br>"}>
              </Title>

              <Button className="btn-box fs--16 swpf--uppercase" use={"page"} href={"/#kontakt"} content={"kontaktujte nás"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box" content={"+420 737 282 756<br>info@hudliskastodola.cz<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}